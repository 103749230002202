import { alias, ApiObject, identify, resetAnonymousId } from '@hbf/analytics';

import {
  getUserId,
  getIsAliased,
  getUserIdAlias,
  getUserTraits,
  getAnalyticsUserData,
  getUserUUID,
} from 'ha/modules/AuthLogic/selectors';
import { Thunk } from 'ha/myredux/types';
import { CookieConsent } from 'ha/services/analytics/types';

import {
  identifyAnonymously,
  identifyRockerbox,
  identifyWithRockerbox,
} from './utils';

export const identifyUser =
  (
    {
      isCookieConsentEnabled,
      isAnonymousTrackingEnabled,
      hasUserRejectedAllCategories,
    }: CookieConsent,
    userTraits?: ApiObject,
  ): Thunk =>
  (_, getState, { analytics }) => {
    const state = getState();

    const userId = getUserId(state);

    const withAnonymousTracking =
      isCookieConsentEnabled && isAnonymousTrackingEnabled;

    if (withAnonymousTracking) {
      resetAnonymousId(hasUserRejectedAllCategories);
    }

    if (!userId) {
      // Needs to call identify, to set new anonymous id on device mode destinations like mixpanel
      identify('', userTraits);
      analytics.userIdentificationComplete();
      return;
    }

    const aliasId = getUserIdAlias(state);

    // @todo(nader) find out a way to disregard anonymous id when user is logged in and connection between pre-login session and user is established
    // const user = getUser(state);
    // // if user last activity is less than 1 minute, then override anonymous id with with user id
    // if (Date.now() - new Date(user!.lastActivityAt).getTime() < 60 * 1000) {
    //   setAnonymousId(aliasId);
    // }

    const userTraitsBaseline = getUserTraits(state);
    const userDataAnalytics = getAnalyticsUserData(state);
    const identifyPayload: ApiObject = {
      ...userTraitsBaseline,
      ...userDataAnalytics,
      ...userTraits,
    };
    const userUUID = getUserUUID(state);
    const isAliased = getIsAliased(state);

    if (withAnonymousTracking && hasUserRejectedAllCategories) {
      // authenticated user id with anonymous tracking

      identifyRockerbox(userUUID, identifyPayload);

      identifyAnonymously(identifyPayload);

      analytics.userIdentificationComplete();
      return;
    }

    // Verify if merging a new identity with an existing user is required
    if (isAliased) {
      identifyWithRockerbox(userUUID, identifyPayload, aliasId);

      analytics.userIdentificationComplete();
    } else {
      alias(aliasId);

      setTimeout(() => {
        identifyWithRockerbox(userUUID, identifyPayload, aliasId);

        analytics.userIdentificationComplete();
      }, 2000);
    }
  };
