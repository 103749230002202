import { MakeCall } from '../createMakeCall';
import { FavoriteUnitType } from '../types/inventory/FavoriteUnitType';

export interface FavoriteUnitTypesResource {
  favorites: FavoriteUnitType[];
  count: number;
}

export interface QueryOptions {
  userId: number;
  /**
   * @description comma-separated string with possible values: `unit-types`, `photos`
   */
  expand?: string;
}

interface FetchOptions {
  keepalive: boolean;
}

export interface Options {
  query?: QueryOptions;
  fetch?: FetchOptions;
}

const getFavoriteUnitTypes = (makeCall: MakeCall) => (options?: Options) =>
  makeCall<FavoriteUnitTypesResource>('GET', '/api/v2/unit-types/favorites', {
    query: { ...options?.query },
    ...options?.fetch,
  });
export { getFavoriteUnitTypes };
